const metroData = { 
    'Totals':['000s',15670,8036,2156,1716,4359,3677,5018,8376,5931,7696,7974,11562,2206,2293,881,3284,6428,8042,12806,12356,8517,13761,853,2401],
    '1':['000s',10241,5287,1119,857,2638,2649,3544,5635,4232,5183,5058,7815,1476,1551,605,2174,4320,5326,8421,8044,5745,9076,570,1712],
    '2':['000s',4468,2659,711,572,1474,1185,939,2361,1800,2452,2016,3241,801,786,298,1487,1969,2509,3713,3723,2393,3947,313,622],
    '3':['000s',4412,2550,575,420,1315,1235,1136,2335,1785,2277,2135,3228,649,682,258,1091,1887,2369,3641,3551,2342,3895,218,682],
    '4':['000s',2727,1679,357,272,917,762,591,1442,1141,1476,1251,1965,430,469,171,779,1191,1495,2239,2246,1436,2420,158,385],
    '1,2':['000s',11660,6104,1422,1111,3161,2943,3786,6326,4701,5909,5751,8769,1707,1787,702,2656,4895,6075,9576,9234,6442,10310,655,1877],
    '1,3':['000s',11738,6123,1399,1072,3140,2983,3875,6380,4751,5896,5843,8850,1682,1758,688,2539,4940,6114,9641,9262,6479,10374,638,1901],
    '1,4':['000s',11129,5818,1275,980,2971,2846,3710,6067,4549,5620,5509,8423,1608,1694,661,2428,4694,5788,9122,8780,6169,9843,614,1822],
    '2,3':['000s',6960,4062,1074,837,2233,1829,1580,3664,2756,3667,3292,5030,1148,1157,434,1964,3020,3807,5759,5704,3685,6132,426,998],
    '2,4':['000s',5814,3477,895,711,1942,1536,1230,3065,2350,3139,2675,4184,1003,1012,375,1765,2558,3225,4819,4812,3074,5138,386,812],
    '3,4':['000s',4916,2820,613,452,1430,1390,1320,2623,1997,2535,2381,3597,716,756,288,1172,2101,2615,4042,3937,2620,4338,242,762],
    '1,2,3':['000s',12568,6589,1612,1258,3462,3127,3986,6768,4989,6324,6244,9376,1831,1912,742,2824,5273,6549,10322,9972,6884,11086,695,1989],
    '1,2,4':['000s',12135,6374,1508,1181,3333,3041,3880,6557,4861,6135,6000,9082,1784,1864,724,2754,5102,6325,9955,9620,6666,10718,680,1938],
    '1,3,4':['000s',11898,6210,1413,1084,3181,3029,3926,6468,4812,5977,5921,8959,1705,1782,697,2563,5007,6191,9760,9387,6563,10507,646,1926],
    '2,3,4':['000s',7373,4285,1105,862,2326,1959,1727,3898,2938,3881,3493,5327,1200,1214,458,2028,3205,4010,6089,6017,3912,6496,448,1064],
    '1,2,3,4':['000s',12704,6662,1625,1270,3495,3167,4028,6841,5044,6392,6312,9468,1850,1929,749,2843,5331,6617,10424,10076,6955,11202,703,2010],
    }
    const metroPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',65.35,65.79,51.9,49.94,60.52,72.04,70.63,67.28,71.35,67.35,63.43,67.59,66.91,67.64,68.67,66.2,67.21,66.23,65.76,65.1,67.45,65.95,66.82,71.3],
    '2':['Vert%',28.51,33.09,32.98,33.33,33.82,32.23,18.71,28.19,30.35,31.86,25.28,28.03,36.31,34.28,33.83,45.28,30.63,31.2,28.99,30.13,28.1,28.68,36.69,25.91],
    '3':['Vert%',28.16,31.73,26.67,24.48,30.17,33.59,22.64,27.88,30.1,29.59,26.77,27.92,29.42,29.74,29.28,33.22,29.36,29.46,28.43,28.74,27.5,28.3,25.56,28.4],
    '4':['Vert%',17.4,20.89,16.56,15.85,21.04,20.72,11.78,17.22,19.24,19.18,15.69,17,19.49,20.45,19.41,23.72,18.53,18.59,17.48,18.18,16.86,17.59,18.52,16.03],
    '1,2':['Vert%',74.41,75.96,65.96,64.74,72.52,80.04,75.45,75.53,79.26,76.78,72.12,75.84,77.38,77.93,79.68,80.88,76.15,75.54,74.78,74.73,75.64,74.92,76.79,78.18],
    '1,3':['Vert%',74.91,76.19,64.89,62.47,72.03,81.13,77.22,76.17,80.1,76.61,73.28,76.54,76.25,76.67,78.09,77.31,76.85,76.03,75.29,74.96,76.07,75.39,74.79,79.18],
    '1,4':['Vert%',71.02,72.4,59.14,57.11,68.16,77.4,73.93,72.43,76.7,73.02,69.09,72.85,72.89,73.88,75.03,73.93,73.02,71.97,71.23,71.06,72.43,71.53,71.98,75.89],
    '2,3':['Vert%',44.42,50.55,49.81,48.78,51.23,49.74,31.49,43.74,46.47,47.65,41.28,43.5,52.04,50.46,49.26,59.81,46.98,47.34,44.97,46.16,43.27,44.56,49.94,41.57],
    '2,4':['Vert%',37.1,43.27,41.51,41.43,44.55,41.77,24.51,36.59,39.62,40.79,33.55,36.19,45.47,44.13,42.57,53.75,39.79,40.1,37.63,38.94,36.09,37.34,45.25,33.82],
    '3,4':['Vert%',31.37,35.09,28.43,26.34,32.81,37.8,26.31,31.32,33.67,32.94,29.86,31.11,32.46,32.97,32.69,35.69,32.69,32.52,31.56,31.86,30.76,31.52,28.37,31.74],
    '1,2,3':['Vert%',80.2,81.99,74.77,73.31,79.42,85.04,79.43,80.8,84.12,82.17,78.3,81.09,83,83.38,84.22,85.99,82.03,81.43,80.6,80.71,80.83,80.56,81.48,82.84],
    '1,2,4':['Vert%',77.44,79.32,69.94,68.82,76.46,82.7,77.32,78.28,81.96,79.72,75.24,78.55,80.87,81.29,82.18,83.86,79.37,78.65,77.74,77.86,78.27,77.89,79.72,80.72],
    '1,3,4':['Vert%',75.93,77.28,65.54,63.17,72.98,82.38,78.24,77.22,81.13,77.66,74.25,77.49,77.29,77.71,79.11,78.05,77.89,76.98,76.21,75.97,77.06,76.35,75.73,80.22],
    '2,3,4':['Vert%',47.05,53.32,51.25,50.23,53.36,53.28,34.42,46.54,49.54,50.43,43.8,46.07,54.4,52.94,51.99,61.75,49.86,49.86,47.55,48.7,45.93,47.21,52.52,44.31],
    '1,2,3,4':['Vert%',81.07,82.9,75.37,74.01,80.18,86.13,80.27,81.67,85.04,83.06,79.16,81.89,83.86,84.13,85.02,86.57,82.93,82.28,81.4,81.55,81.66,81.4,82.42,83.72],
    }
    const nationalData = { 
    'Totals':['000s',22119,10939,2958,2338,5838,5101,7568,11887,8174,10886,11233,16627,2934,3152,1199,4216,8980,11206,18134,17033,11933,19371,1159,3569],
    '1':['000s',14215,7143,1566,1189,3527,3615,5097,7814,5728,7153,7062,11014,1955,2077,800,2789,5951,7313,11761,10977,7877,12565,761,2448],
    '2':['000s',5900,3447,924,734,1872,1576,1321,3097,2353,3235,2665,4363,1004,1017,372,1756,2588,3264,4895,4806,3149,5209,398,875],
    '3':['000s',5958,3358,779,567,1699,1659,1623,3141,2354,3112,2846,4416,850,916,349,1313,2529,3149,4940,4676,3176,5272,323,955],
    '4':['000s',3566,2179,461,349,1179,1001,798,1877,1486,1928,1638,2603,554,590,217,910,1562,1933,2954,2854,1884,3173,218,541],
    '1,2':['000s',16129,8227,1963,1518,4189,4038,5458,8754,6359,8137,7993,12336,2252,2393,923,3354,6740,8334,13327,12547,8834,14235,871,2691],
    '1,3':['000s',16274,8256,1937,1469,4170,4085,5605,8833,6410,8164,8110,12458,2233,2376,918,3231,6800,8382,13434,12597,8905,14346,873,2719],
    '1,4':['000s',15403,7850,1768,1348,3960,3889,5334,8386,6144,7754,7649,11845,2129,2265,877,3093,6455,7924,12706,11926,8456,13600,831,2602],
    '2,3':['000s',9383,5362,1425,1097,2881,2481,2273,4922,3683,4981,4402,6900,1471,1533,570,2368,4043,5052,7764,7503,4977,8266,578,1441],
    '2,4':['000s',7716,4548,1162,914,2496,2052,1726,4044,3107,4168,3547,5661,1275,1302,472,2101,3378,4216,6400,6239,4091,6820,506,1168],
    '3,4':['000s',6618,3715,831,607,1869,1847,1859,3505,2629,3463,3155,4909,932,1006,383,1401,2814,3469,5475,5171,3544,5848,355,1079],
    '1,2,3':['000s',17406,8888,2212,1707,4585,4303,5780,9376,6760,8743,8663,13207,2418,2575,989,3565,7260,8980,14359,13542,9466,15322,943,2862],
    '1,2,4':['000s',16786,8603,2076,1609,4426,4177,5596,9071,6583,8457,8329,12784,2351,2496,958,3476,7024,8669,13851,13061,9152,14803,913,2786],
    '1,3,4':['000s',16497,8381,1957,1486,4237,4144,5676,8956,6492,8279,8217,12617,2262,2407,931,3257,6896,8487,13605,12770,9023,14533,886,2763],
    '2,3,4':['000s',9910,5658,1465,1129,3021,2636,2450,5215,3917,5253,4657,7289,1538,1603,597,2434,4281,5307,8193,7900,5271,8730,607,1541],
    '1,2,3,4':['000s',17593,8996,2230,1723,4641,4355,5835,9479,6835,8838,8755,13340,2444,2599,999,3586,7344,9071,14506,13684,9566,15483,956,2900],
    }
    const nationalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',64.27,65.3,52.94,50.86,60.41,70.87,67.35,65.74,70.08,65.71,62.87,66.24,66.63,65.89,66.72,66.15,66.27,65.26,64.86,64.45,66.01,64.87,65.66,68.59],
    '2':['Vert%',26.67,31.51,31.24,31.39,32.07,30.9,17.46,26.05,28.79,29.72,23.72,26.24,34.22,32.27,31.03,41.65,28.82,29.13,26.99,28.22,26.39,26.89,34.34,24.52],
    '3':['Vert%',26.94,30.7,26.34,24.25,29.1,32.52,21.45,26.42,28.8,28.59,25.34,26.56,28.97,29.06,29.11,31.14,28.16,28.1,27.24,27.45,26.62,27.22,27.87,26.76],
    '4':['Vert%',16.12,19.92,15.58,14.93,20.2,19.62,10.54,15.79,18.18,17.71,14.58,15.66,18.88,18.72,18.1,21.58,17.39,17.25,16.29,16.76,15.79,16.38,18.81,15.16],
    '1,2':['Vert%',72.92,75.21,66.36,64.93,71.75,79.16,72.12,73.64,77.8,74.75,71.16,74.19,76.76,75.92,76.98,79.55,75.06,74.37,73.49,73.66,74.03,73.49,75.15,75.4],
    '1,3':['Vert%',73.57,75.47,65.48,62.83,71.43,80.08,74.06,74.31,78.42,75,72.2,74.93,76.11,75.38,76.56,76.64,75.72,74.8,74.08,73.96,74.62,74.06,75.32,76.18],
    '1,4':['Vert%',69.64,71.76,59.77,57.66,67.83,76.24,70.48,70.55,75.17,71.23,68.09,71.24,72.56,71.86,73.14,73.36,71.88,70.71,70.07,70.02,70.86,70.21,71.7,72.91],
    '2,3':['Vert%',42.42,49.02,48.17,46.92,49.35,48.64,30.03,41.41,45.06,45.76,39.19,41.5,50.14,48.64,47.54,56.17,45.02,45.08,42.81,44.05,41.71,42.67,49.87,40.38],
    '2,4':['Vert%',34.88,41.58,39.28,39.09,42.75,40.23,22.81,34.02,38.01,38.29,31.58,34.05,43.46,41.31,39.37,49.83,37.62,37.62,35.29,36.63,34.28,35.21,43.66,32.73],
    '3,4':['Vert%',29.92,33.96,28.09,25.96,32.01,36.21,24.56,29.49,32.16,31.81,28.09,29.52,31.77,31.92,31.94,33.23,31.34,30.96,30.19,30.36,29.7,30.19,30.63,30.23],
    '1,2,3':['Vert%',78.69,81.25,74.78,73.01,78.54,84.36,76.37,78.88,82.7,80.31,77.12,79.43,82.41,81.69,82.49,84.56,80.85,80.14,79.18,79.5,79.33,79.1,81.36,80.19],
    '1,2,4':['Vert%',75.89,78.65,70.18,68.82,75.81,81.89,73.94,76.31,80.54,77.69,74.15,76.89,80.13,79.19,79.9,82.45,78.22,77.36,76.38,76.68,76.69,76.42,78.77,78.06],
    '1,3,4':['Vert%',74.58,76.62,66.16,63.56,72.58,81.24,75,75.34,79.42,76.05,73.15,75.88,77.1,76.36,77.65,77.25,76.79,75.74,75.02,74.97,75.61,75.02,76.45,77.42],
    '2,3,4':['Vert%',44.8,51.72,49.53,48.29,51.75,51.68,32.37,43.87,47.92,48.25,41.46,43.84,52.42,50.86,49.79,57.73,47.67,47.36,45.18,46.38,44.17,45.07,52.37,43.18],
    '1,2,3,4':['Vert%',79.54,82.24,75.39,73.7,79.5,85.38,77.1,79.74,83.62,81.19,77.94,80.23,83.3,82.46,83.32,85.06,81.78,80.95,79.99,80.34,80.16,79.93,82.48,81.26],
    }
    const regionalData = { 
    'Totals':['000s',6449,2903,802,622,1479,1424,2550,3511,2243,3190,3259,5065,728,860,318,932,2552,3164,5328,4677,3416,5611,306,1169],
    '1':['000s',3974,1856,447,332,889,966,1552,2178,1496,1970,2003,3199,479,525,195,615,1631,1987,3340,2933,2132,3489,192,736],
    '2':['000s',1431,788,213,163,397,391,382,736,553,783,648,1121,203,232,74,269,619,755,1182,1083,755,1262,85,253],
    '3':['000s',1546,808,205,146,384,424,486,806,569,835,711,1188,200,234,91,222,643,780,1300,1125,834,1377,104,273],
    '4':['000s',838,501,104,77,262,238,207,435,345,452,387,637,124,120,46,131,370,438,715,608,449,753,60,156],
    '1,2':['000s',4470,2123,541,407,1028,1095,1672,2428,1658,2228,2242,3567,545,606,222,698,1846,2259,3751,3313,2392,3925,216,814],
    '1,3':['000s',4535,2133,538,397,1030,1102,1730,2454,1659,2268,2267,3608,551,618,230,693,1860,2268,3793,3335,2426,3972,235,818],
    '1,4':['000s',4275,2032,493,368,989,1043,1624,2319,1595,2134,2140,3422,521,572,216,664,1761,2136,3583,3146,2287,3756,217,780],
    '2,3':['000s',2423,1299,351,260,648,651,694,1258,927,1314,1110,1870,323,376,137,403,1023,1245,2005,1799,1292,2135,152,443],
    '2,4':['000s',1902,1071,268,203,555,516,496,979,757,1029,873,1477,272,291,97,336,820,991,1581,1426,1017,1682,120,356],
    '3,4':['000s',1702,895,218,155,439,456,539,881,631,928,774,1312,216,250,95,228,714,854,1433,1234,924,1510,113,316],
    '1,2,3':['000s',4838,2300,600,449,1124,1176,1794,2608,1772,2420,2418,3831,587,664,247,741,1987,2431,4038,3570,2583,4236,248,873],
    '1,2,4':['000s',4652,2229,568,428,1093,1136,1716,2515,1722,2322,2329,3702,567,632,234,722,1921,2344,3896,3441,2485,4085,233,848],
    '1,3,4':['000s',4599,2171,544,402,1056,1115,1750,2488,1680,2303,2296,3657,557,624,233,694,1889,2296,3845,3382,2460,4025,241,837],
    '2,3,4':['000s',2536,1372,360,267,695,677,723,1317,979,1372,1164,1962,338,389,140,406,1077,1297,2105,1883,1359,2235,160,477],
    '1,2,3,4':['000s',4889,2334,605,453,1146,1188,1807,2638,1791,2447,2443,3873,593,670,250,743,2013,2454,4082,3608,2611,4281,253,890],
    }
    const regionalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',61.62,63.93,55.74,53.38,60.11,67.84,60.86,62.03,66.7,61.76,61.46,63.16,65.8,61.05,61.32,65.99,63.91,62.8,62.69,62.71,62.41,62.18,62.75,62.96],
    '2':['Vert%',22.19,27.14,26.56,26.21,26.84,27.46,14.98,20.96,24.65,24.55,19.88,22.13,27.88,26.98,23.27,28.86,24.26,23.86,22.18,23.16,22.1,22.49,27.78,21.64],
    '3':['Vert%',23.97,27.83,25.56,23.47,25.96,29.78,19.06,22.96,25.37,26.18,21.82,23.46,27.47,27.21,28.62,23.82,25.2,24.65,24.4,24.05,24.41,24.54,33.99,23.35],
    '4':['Vert%',12.99,17.26,12.97,12.38,17.71,16.71,8.12,12.39,15.38,14.17,11.87,12.58,17.03,13.95,14.47,14.06,14.5,13.84,13.42,13,13.14,13.42,19.61,13.34],
    '1,2':['Vert%',69.31,73.13,67.46,65.43,69.51,76.9,65.57,69.15,73.92,69.84,68.79,70.42,74.86,70.47,69.81,74.89,72.34,71.4,70.4,70.84,70.02,69.95,70.59,69.63],
    '1,3':['Vert%',70.32,73.48,67.08,63.83,69.64,77.39,67.84,69.89,73.96,71.1,69.56,71.23,75.69,71.86,72.33,74.36,72.88,71.68,71.19,71.31,71.02,70.79,76.8,69.97],
    '1,4':['Vert%',66.29,70,61.47,59.16,66.87,73.24,63.69,66.05,71.11,66.9,65.66,67.56,71.57,66.51,67.92,71.24,69,67.51,67.25,67.27,66.95,66.94,70.92,66.72],
    '2,3':['Vert%',37.57,44.75,43.77,41.8,43.81,45.72,27.22,35.83,41.33,41.19,34.06,36.92,44.37,43.72,43.08,43.24,40.09,39.35,37.63,38.46,37.82,38.05,49.67,37.9],
    '2,4':['Vert%',29.49,36.89,33.42,32.64,37.53,36.24,19.45,27.88,33.75,32.26,26.79,29.16,37.36,33.84,30.5,36.05,32.13,31.32,29.67,30.49,29.77,29.98,39.22,30.45],
    '3,4':['Vert%',26.39,30.83,27.18,24.92,29.68,32.02,21.14,25.09,28.13,29.09,23.75,25.9,29.67,29.07,29.87,24.46,27.98,26.99,26.9,26.38,27.05,26.91,36.93,27.03],
    '1,2,3':['Vert%',75.02,79.23,74.81,72.19,76,82.58,70.35,74.28,79,75.86,74.19,75.64,80.63,77.21,77.67,79.51,77.86,76.83,75.79,76.33,75.61,75.49,81.05,74.68],
    '1,2,4':['Vert%',72.14,76.78,70.82,68.81,73.9,79.78,67.29,71.63,76.77,72.79,71.46,73.09,77.88,73.49,73.58,77.47,75.27,74.08,73.12,73.57,72.75,72.8,76.14,72.54],
    '1,3,4':['Vert%',71.31,74.78,67.83,64.63,71.4,78.3,68.63,70.86,74.9,72.19,70.45,72.2,76.51,72.56,73.27,74.46,74.02,72.57,72.17,72.31,72.01,71.73,78.76,71.6],
    '2,3,4':['Vert%',39.32,47.26,44.89,42.93,46.99,47.54,28.35,37.51,43.65,43.01,35.72,38.74,46.43,45.23,44.03,43.56,42.2,40.99,39.51,40.26,39.78,39.83,52.29,40.8],
    '1,2,3,4':['Vert%',75.81,80.4,75.44,72.83,77.48,83.43,70.86,75.14,79.85,76.71,74.96,76.47,81.46,77.91,78.62,79.72,78.88,77.56,76.61,77.14,76.43,76.3,82.68,76.13],
    }


const graph_source = 'Nielsen CMV, National Survey 9 2024'

const exportedObject = {
    metroData,
    metroPercentage,
    nationalData,
    nationalPercentage,
    regionalData,
    regionalPercentage,
    graph_source

}

export default exportedObject


    